<template>
  <head>
    <title>敷布団</title>
  </head>
  <aheader/>
  <div class="block">
    <section class="hero is-warning">
      <div class="hero-body">
        <p class="title">
          取扱商品(敷き布団)
        </p>
        <p class="subtitle">
          オンライン決済が可能な取扱商品
        </p>
      </div>
    </section>
  </div>
  <div class="block">
    <div class="content is-normal">
      <section class="section">
        <h1 class="title" style="text-align:left">敷き布団のラインナップ</h1>
        <div class="block">
          <h3 style="text-align:left">
            <span class="icon-text">
              <span>
                １．商品の名前
              </span>
              <span class="icon">
                <i class="fa-solid fa-cart-shopping"></i>
              </span>
            </span>
          </h3>
          <div class="bulma-clearfix-mixin">
            <img height="349" width="500" style="float: left;" src="kari1.jpg">
            <h4>商品についての説明―商品の特徴やどのような人に向けた商品なのかの説明を入れる。<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></h4>
          </div>
        </div>
        <br/>
        <div class="block">
          <h3 style="text-align:left">
            <span class="icon-text">
              <span>
                ２．商品の名前
              </span>
              <span class="icon">
                <i class="fa-solid fa-cart-shopping"></i>
              </span>
            </span>
          </h3>
          <div class="bulma-clearfix-mixin">
            <img height="349" width="500" style="float: left;" src="kari1.jpg">
            <h4>商品についての説明―商品の特徴やどのような人に向けた商品なのかの説明を入れる。<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></h4>
          </div>
        </div>
        <br/>
        <div class="block">
          <h3 style="text-align:left">
            <span class="icon-text">
              <span>
                ３．商品の名前
              </span>
              <span class="icon">
                <i class="fa-solid fa-cart-shopping"></i>
              </span>
            </span>
          </h3>
          <div class="bulma-clearfix-mixin">
            <img height="349" width="500" style="float: left;" src="kari1.jpg">
            <h4>商品についての説明―商品の特徴やどのような人に向けた商品なのかの説明を入れる。<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></h4>
          </div>
        </div>
        <br/>
      </section>
    </div>
  </div>
  <div class="block">
    <section class="hero is-halfhight is-light">
      <div class="hero-body">
        <div class="content is-normal">
          <h1>オンライン決済について</h1>
          <div class="bulma-clearfix-mixin">
            <img height="209" width="300" style="float: top;" src="kari3.jpg">
            <h4><br/>上のイラストは「工事中」みたいなお辞儀のイラストが良いかなと思います。<br/>こちらのホームページで掲載している商品はおぐら屋で取り扱っている商品の一部です。<br/>オンライン購入は店舗で一度購入した商品を再購入する際にご利用を検討してください。<br/>オンライン購入される際は店舗で商品を確認をお願いします。</h4>
          </div>
        </div>
      </div>
    </section>
  </div>
  <afooter/>
</template>
